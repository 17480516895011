<template>
  <v-app>
  
      
    <v-dialog v-model="msg_alert_dialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          Mensagem
        </v-card-title>
        <v-card-text>
         {{ this.msg_alert }}
        </v-card-text>
        
        <v-card-actions>
          <v-btn color="primary" @click="msg_alert_dialog = false" class="ml-auto">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


      <v-dialog v-model="showFilter" persistent max-width="500px">
        <v-card>
          <v-card-title>
            Detalhes do Cliente
          </v-card-title>
          <v-card-text>
            <strong>Status da Pessoa:</strong> 
            
            <v-chip small
           :color="getStatus(this.selectedRow.status_pessoa)"
           text-color="white">
               {{this.selectedRow.status_pessoa}}                   
             </v-chip>
           <br>

           <strong>Tipo de Pessoa:</strong> {{ this.selectedRow.tipo_pessoa }}
           <br>
           <br>


            <strong>Nome:</strong> {{ this.selectedRow.nome }}
            <br>
            <strong>Tipo de Plano:</strong> {{ this.selectedRow.tipo_plano }}
            <br>
            <strong>Número de Contrato:</strong> {{ this.selectedRow.num_contrato }}
            <br>
            <strong>Data do Contrato:</strong> {{ this.selectedRow.inclusão_contrato }}
            <br>
            <strong>Grau de Relação:</strong> {{ this.selectedRow.grau_relacao }}
            <br>
         
           
            <strong>Data de Carência:</strong> {{ this.selectedRow.data_carencia }}
            <br>
            <strong>Data de Nascimento:</strong> {{ this.selectedRow.data_nascimento }}
            <br>
            <strong>Data de Falecimento:</strong> {{ this.selectedRow.data_falecimento }}
            <br>
            <strong>Qtd. Documentos em Atraso:</strong> {{ this.selectedRow.qtd_documentos_atraso }}
          </v-card-text>
          
          <v-card-actions>
            <v-btn color="primary" @click="closeDetailsDialog" class="ml-auto">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-container>
        <v-card elevation="0" style="margin-top: 0px !important; margin-bottom: -30px !important; overflow: hidden;background-color:transparent" class="px-5 py-1">
        <v-row align="center">
          <v-col cols="2">
          </v-col>
          <v-col cols="2">
            <!-- Campo de seleção -->

            <v-select v-model="opcao" :items="opcoes" label="Opção" dense solo-inverted></v-select>
          </v-col>
          <v-col cols="6">
            <!-- Campo de texto simples -->
            <v-text-field v-model="valor" label="Texto de Pesquisa" dense solo-inverted></v-text-field>
          </v-col>
         
        </v-row>
        </v-card>
        <br/>
        <v-divider></v-divider>

        <v-card-actions class="justify-end">

          <v-btn color="green" small   @click="fetchData(pageNumber)">Procurar</v-btn>


        </v-card-actions>


      </v-container>

      <dividir></dividir>


    
  
    <v-container
      id="contrato"
      fluid
      tag="section">
      
     
    <base-material-card
    icon="mdi-magnify"
    title="Lista de Cliente"
    color="green" style="margin-top:0px !important"
    class="px-5 py-0"
  > 

      <v-row>
        <v-col cols="12">
  
          <v-data-table
          :headers="this.headers_clientes"
          :items="lista_dessert"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :items-per-page-text="'Registros por página'" 
          class="elevation-0"
  
        >
  
        <template v-slot:top>
          <v-progress-linear
            v-if="isLoading" 
            indeterminate
            color="primary"
          ></v-progress-linear>
        </template>
  
       

        <template v-slot:item="row">
          <tr :class="{'gray-row': row.index % 2 !== 0}">
            <td style="width: 24%; font-size: 14px;">
              <span class="font-weight-bold">{{ row.item.num_contrato }} </span>

              -
              <v-chip small
              :color="getStatus(row.item.status_contrato)"
              text-color="white">
                  {{row.item.status_contrato}}                    
                  
        </v-chip>

            </td>
            

            <td style="width: 32%; font-size: 14px;"><span class="font-weight-bold">{{ row.item.nome }}</span></td>

            <td style="width: 15%; font-size: 14px;"><span class="font-weight-bold">{{ row.item.cpf }}</span></td>
            <td style="width: 22%; font-size: 14px;"><span class="font-weight-bold">{{ row.item.tipo_plano}}</span></td>
          
            <td style="width: 12%; font-size: 14px;">
              
              <v-chip small
              :color="getEmpresa(row.item.empresa )"
              text-color="white">
                  {{row.item.empresa }}                    
                  
                </v-chip>
</td>

            <td style="width: 8%; font-size: 14px;"> 

              <v-btn  color="orange" small @click="openDetailsDialog(row.item)"> + Detalhe  
              </v-btn>

            </td>

  
        </tr>
      </template> 
     
  
      <template v-slot:footer="{  }">
  
        <v-spacer></v-spacer> <br/>
        <v-row class="d-flex">
            <!-- Legenda personalizada -->
            <div class="d-flex align-center">
              <span class="mr-2" style="margin-left: 12px; margin-top: 8px; font-size: 14px; color: #000;">Total encontrado: {{ totalRecords }}</span> 
            </div>
        </v-row>
    
  
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="6" style="background-dolor:blue">
            <!-- Paginação padrão -->
            <v-pagination v-model="currentPage" :length="totalPages" @input="changePage" :max="3"></v-pagination>
          </v-col>
        </v-row>  
       
        
  
      </template>
    
      
  
  
      </v-data-table>
  
     
      
        
        </v-col>  
       
      
    
  
      </v-row>
     
  
      <br/>
  
     
      </base-material-card>
  
  
    </v-container>
  
  </v-app> 
  </template>
  
  <script>
  import Contrato from '../../services/contrato'
  import FiltroComponent from "./components/Filtro.vue"; // Certifique-se de que o caminho esteja correto

  
    export default {
    components: {
      FiltroComponent
    },
    
    filters: {
      formatDate(date) {
        if (!date) return '';
        const parsedDate = new Date(date);
        const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
        const year = parsedDate.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },
  
  
    mounted() {
  
  
  
      
    },
  
      name: 'Contrato',
   
      data () {
        return {
  
          valor: '',
          rules: {
            required: value => !!value || 'Obrigatório.',
          },
          
          isLoading: false,
          currentPage: 1, // Página atual
          itemsPerPage: 10, // Itens por página
          pageNumber: 1, // Número da página
          totalPages:0,
          totalRecords:0,
          showProgress: false,
          showFilter: false,
          selectedRow: {},
          msg_alert: null,
          msg_alert_dialog: false,
          opcao: 'CPF', // Valor padrão selecionado
          opcoes: ['CPF', 'NOME', 'CONTRATO'], // Opções disponíveis no select

          headers_clientes: [
            {
              text: 'Contrato',
              align: 'start',
              sortable: false,
              value: 'num_contrato',
            },

            

            { text: 'Nome', value: 'nome',   sortable: false, },
            { text: 'CPF', value: 'cpf',   sortable: false, },
            { text: 'Plano', value: 'tipo_plano' ,   sortable: false, },
            { text: 'Empresa', value: 'empresa' ,   sortable: false, },

          ],
  
          lista_dessert: [],         
     

        }
    },
  
    computed: {
      startIndex() {
        return (this.currentPage - 1) * this.itemsPerPage;
      },   
     
    },
  
    created() {
      // Defina a data atual como valor inicial para selectedStartDate
      this.loadPageData();
     
    },
  
  
      methods: {
  
       loadPageData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      },
      
      getEmpresa (status) {

        switch (status) {
          case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
          case 'PARQUE DAS FLORES' : return 'orange lighten-1'
          case 'PREVIDA' : return 'blue lighten-1'
          case 'CEMITÉRIO PREVIDA' : return 'cyan lighten-1'
          case 'PARQUE DO AGRESTE' : return 'indigo lighten-1'
          default: 'success'
        }
      },

      getStatus (status) {

          switch (status) {
            case 'Ativo' : return 'green lighten-1'
            case 'Inativo' : return 'red lighten-1'
            case 'Cancelado' : return 'red lighten-1'
            default: 'success'
          }
      },

      closeDetailsDialog() {
        this.showFilter = false;
      },

      openDetailsDialog(selectedItem) {
        this.selectedRow = selectedItem;
        this.showFilter = true;
      },

      changePage() {
          this.isLoading = true;
          this.loadPageData();
          this.fetchData(this.currentPage);
  
       },
       
      
        formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
         },
         


          fetchData(pageNumber) {

            if (!this.valor) {
                  // Exibe uma mensagem de erro ou lida com a validação
                  this.msg_alert = 'Por favor, preencha o campo de pesquisa.';
                  this.msg_alert_dialog = true;
                  return; // Impede que a solicitação seja feita
                }

                // Verifica a opção selecionada
                if (this.opcao === 'CPF' && this.valor.length <10) {
                  // Se a opção for "CPF", verifica se o comprimento é 11 dígitos
                  this.msg_alert = 'O CPF deve ter 11 dígitos ';
                  this.msg_alert_dialog = true;

                  return; // Impede que a solicitação seja feita
                } else if (this.opcao === 'NOME' && this.valor.length < 6) {
                  this.msg_alert = 'O nome deve ter pelo menos 6 caracteres.';
                  this.msg_alert_dialog = true;
                  return; // Impede que a solicitação seja feita
                }else if (this.opcao === 'CONTRATO' && this.valor.length < 4) {
                  this.msg_alert = 'O nome deve ter pelo menos 4 caracteres.';
                  this.msg_alert_dialog = true;
                  return; // Impede que a solicitação seja feita
                }

            this.isLoading = true;

              Contrato.lista_cliente(this.valor, this.opcao, pageNumber, this.itemsPerPage)
                .then(response => {
                  if (response.status === 200) {
                    this.lista_dessert = response.data.content;
                    this.totalPages = response.data.totalPages; // Total de páginas disponíveis
                    this.loading = false; // Esconder o loading após a pesquisa
                    this.totalRecords = response.data.totalElements; // Substitua totalRecordsFromApi pelo valor real=

                  }
                })
                .catch(e => {
                  this.isLoading = false;
                  this.lista_dessert = [];
                  this.totalRecords = 0;
                })
                .finally(() => {
                  this.isLoading = false;
                });
            }
      }  
    }
  
    function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }
  
  
  </script>
  
  
  <style scoped>
    .v-progress-circular {
      margin: 1rem;
    }
  
    .custom-gray-background {
      height: 80px;
    }
    
    .row {
      display: flex;
      flex-wrap: wrap;
       flex: 0 1 auto !important; 
      margin: -12px;
  }
  
  
    /* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
    .fill-width {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      display: flex;
    }
    
    /* Centralize horizontalmente */
    .justify-center {
      justify-content: center;
    }
    
    .gray-row {
      background-color: #e7f1ff; /* ou qualquer outra cor cinza que você preferir */
    }
   
    
    </style>
  